import '../static/css/splash.css'
import '../static/css/f7icons.css'
import '../static/css/fonts.css'

window.connectUrl = 'https://connect.orastream.com'
window.showsUrl = 'https://shows-connect.orastream.com'
window.cdnUrl = 'https://brio.orastream.com'
window.apiUrl = connectUrl + '/api'

window.latestVersion = LATEST_APP_VERSION

const loadJs = src => {
    return new Promise(resolve => {    
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = src
        script.onload = resolve
        document.body.appendChild(script)
    })
}

(async () => {
    await loadJs('js/os.js')
    await loadJs('js/capacitor.js')

    if (Capacitor?.platform == 'ios' || Capacitor?.platform == 'android')
        _OS.platform = 'capacitor'

    if (/electron/i.test(navigator.userAgent))
        _OS.platform = 'electron'

    console.log('capacitor:', Capacitor?.platform, location.href)
    
    let userInfo = await _OS.get('userInfo')
    if (!userInfo || !userInfo.id) await new Promise(r => setTimeout(r, 3000))    

    await import('./main')
    await import('./methods')

    let all = []
    all.push(loadJs('js/socket.io.js'))
    all.push(loadJs('js/notification.min.js'))
    all.push(loadJs('js/ga.js'))

    await Promise.all(all)

    if (_OS.platform == "capacitor")
        await loadJs('js/os.mobile.js')        

    if (_OS.platform == "electron")
        await loadJs('js/os.electron.js')

    $$(document).focus()

    console.log('version:', f7.version)
})()